import { ConfigProvider, App as AntdApp } from "antd";
import { useRef } from "react";
import ru from "antd/locale/ru_RU";
import EmlViewer from "./EmlViewer";

export default function App() {

    const modalContainerRef = useRef<HTMLDivElement>(null);
    
    return (
        <ConfigProvider
            locale={ru}
            getPopupContainer={() => modalContainerRef.current as HTMLElement}
            theme={{
                token: {
                    //colorPrimary: "#d000ff",
                    borderRadius: 0,
                    wireframe: false,
                    //colorInfo: "#d000ff",
                },
                components: {
                    Select: {
                        borderRadius: 0,
                        colorBorder: '#000',
                        controlHeight: 42,
                    },
                    Input: {
                        borderRadius: 0,
                        colorBorder: '#000',
                        controlHeight: 42,
                    },
                    InputNumber: {
                        borderRadius: 0,
                        colorBorder: '#000',
                        controlHeight: 42,
                    },
                    Button: {
                        borderRadius: 0,
                        colorBorder: '#000',
                        controlHeight: 42,
                        padding: 30,
                    }
                },
            }}
        >
            <AntdApp
                style={{
                    padding: '15px',
                    maxWidth: 1440,
                    margin: '0 auto'
                }}
            >
                <div ref={modalContainerRef}></div>
                <EmlViewer />
            </AntdApp>
        </ConfigProvider>
    );
}