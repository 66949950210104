import PostalMime from "./Parser/postal-mime";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Address, Email } from "./Parser/types";
import IframeResizer, { IFrameObject } from "./IFrameResizer";
import { Progress, Skeleton, Space, Tag, Form, Upload, Button, UploadProps, UploadFile, Alert, Spin } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";

const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    hour12: false
};

const normFile = (e: any) => {

    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
};

export interface IFormatAddressProps {
    address: Address
}

export function FormatAddress({address}: IFormatAddressProps) {

    return (
        <a
            className="email-address"
            href={`mailto:${address.address}`}
        >
            {address.name || `<${address.address}>`}
        </a>
    )
}

export interface IFormatAddressesProps {
    addresses: Address[]
}

export function FormatAddresses({addresses}: IFormatAddressesProps) {

    const render = (address: Address, index: number) => {

        if(address.group) {

            return (
                <>
                    {index > 0 &&
                    <span className="email-address-separator">, </span>}
                    <span className="email-address-group">
                        {address.name}:
                    </span>
                    <FormatAddresses addresses={address.group} />
                    <span className="email-address-group">;</span>
                </>
            );
        }

        return (
            <>
                {index > 0 &&
                <span className="email-address-separator">, </span>}
                <FormatAddress
                    address={address}
                />
            </>
        );
    }

    return (
        <>
            {addresses.map(render)}
        </>
    )
}

export default function EmlViewer() {

    const frameRef = useRef<HTMLIFrameElement>(null);
    const frameResizerRef = useRef<IFrameObject>(null);
    const [message, setMessage] = useState<Email>();

    const [parsing, setParsing] = useState(false);
    const [currentFile, setCurrentFile] = useState<UploadFile<File> | Blob>();
    const [fileList, setFileList] = useState<UploadFile<File>[]>([]);
    const [localUpload, setLocalUpload] = useState(false);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [downloadError, setDownloadError] = useState<any>(null);

    useEffect(() => {

        // msg_id=1907570&user_id=24554&usertoken=9050193B-CFE0-435E-919F-4372123937A1
        async function getEml(signal: AbortSignal) {

            try {

                setLoad(false);
                setLoading(true);
                setMessage(undefined);
                setDownloadError(null);
                setDownloadProgress(0);

                const params              = new URLSearchParams(window.location.search);
                const msgId: number       = parseInt(params.get('msg_id') || '-1');
                const userId: number      = parseInt(params.get('user_id') || '-1');
                const accessToken: string = params.get('usertoken') || '';

                if(msgId === -1
                && userId === -1
                && accessToken === '') {
                    setLocalUpload(true);
                    return;
                }

                const result = await axios.get<Blob>(`https://api.emlviewer.astrapage.ru/file/eml`, {
                    signal: signal,
                    params: {
                        msgId,
                        userId,
                        accessToken
                    },
                    onDownloadProgress: (event) => {

                        if(event.progress) {
                            setDownloadError(null);
                            setDownloadProgress(event.progress * 100);
                        }
                    }
                });

                setCurrentFile(result.data);
                setDownloadProgress(100);
                setLoad(true);
            }
            catch (ex) {
                setDownloadError(ex);
            }
            finally {
                setLoading(false);
            }
        }

        const abortController = new AbortController();
        getEml(abortController.signal);
        return () => abortController.abort();

    }, []);

    useEffect(() => {

        const htmlIframe = frameRef.current;
        const frameResizer = frameResizerRef.current;

        if(message
        && frameResizer
        && htmlIframe
        && htmlIframe.contentWindow) {

            htmlIframe.contentWindow.document.open();
            htmlIframe.contentWindow.document.write(message.html || '');
            htmlIframe.contentWindow.document.close();

            const appLink = `${window.location.protocol}//${window.location.host}`;
            const cssLink = document.createElement("link");
            cssLink.href = `${appLink}/email.css`;
            cssLink.type = "text/css";
            cssLink.rel = "stylesheet";
            htmlIframe.contentWindow.document.getElementsByTagName("head")[0].appendChild(cssLink);

            const iframeScript = document.createElement("script");

            iframeScript.setAttribute('src', `${appLink}/js/iframeResizer.contentWindow.min.js`);
            iframeScript.defer = true;

            htmlIframe.contentWindow.document.getElementsByTagName("head")[0].appendChild(iframeScript);
            htmlIframe.contentWindow.document.querySelectorAll('img').forEach(img => {

                if(/^cid:/.test(img.src)) {

                    // replace with inline attachment
                    const cid = img.src.substring(4).trim();
                    const attachment = message.attachments.find(attachment=>attachment.contentId && attachment.contentId === `<${cid}>`);

                    if(attachment) {
                        img.src = URL.createObjectURL(new Blob([attachment.content], {type: attachment.mimeType}))
                    }
                }
            });

            htmlIframe
            .contentWindow
            .document
            .querySelectorAll('a')
            .forEach(a => {
                a.setAttribute('target', '_blank');
            });

            frameResizer.resize();
        }

    }, [message]);

    useEffect(() => {

        if(fileList.length === 1) {
            setCurrentFile(fileList[0]);
        }

    }, [fileList]);

    useEffect(() => {

        let actual = true;
        const parser = new PostalMime();

        async function worker() {

            if(currentFile) {

                setParsing(true);

                const emailMessageFile = currentFile;
                const emailMessage = await parser.parse(emailMessageFile) as unknown as Email;

                if(actual) {
                    setParsing(false);
                    setMessage(emailMessage);
                    setLoad(true);
                }
            }
        }

        worker();

        return () => {
            actual = false;
        }

    }, [currentFile]);

    return (
        <>
            {(!localUpload && !load) &&
            <div className="email-loader">
                <Progress
                    type="dashboard"
                    percent={Math.floor(downloadProgress)}
                    status={downloadError ? 'exception' : undefined}
                />
                {downloadError
                ? <p>Не удалось загрузить сообщение</p>
                : <p>Загрузка файла сообщения...</p>}
                {downloadError &&
                <Alert
                    style={{width: '100%'}}
                    type="error"
                    message={JSON.stringify(downloadError)}
                />}
            </div>}

            {localUpload &&
            <Upload<File>
                listType="picture"
                fileList={fileList}
                onPreview={(file) => {
                    setCurrentFile(file);
                }}
                onRemove={(file) => {
                    const index = fileList.indexOf(file);
                    const newFileList = fileList.slice();
                    newFileList.splice(index, 1);
                    setFileList(newFileList);
                }}
                beforeUpload={(file) => {
                    setFileList([...fileList, file]);
                    return false;
                }}
            >
                <Button
                    block
                    icon={<UploadOutlined />}
                >
                    Загрузить
                </Button>
            </Upload>}

            {localUpload && <div className="content-divider"></div>}
            
            {parsing &&
            <Spin spinning={parsing}>
                <Alert
                    type="info"
                    message="Обработка..."
                    description="Идет обработка файла, пожалуйста, подождите..."
                />
            </Spin>}

            {parsing && load && message && <div className="content-divider"></div>}

            {load && message &&
            <div className="email-container">

                <div className="info-container">

                    {message.subject &&
                    <div className="subject-content">
                        {message.subject}
                    </div>}

                    {message.from &&
                    <div className="from-container email-info-row">
                        <div className="email-info-label">From:</div>
                        <div className="content">
                            <FormatAddress address={message.from} />
                        </div>
                    </div>}

                    {message.to &&
                    <div className="to-container email-info-row">
                        <div className="email-info-label">To:</div>
                        <div className="content">
                            <FormatAddresses addresses={message.to} />
                        </div>
                    </div>}

                    {message.cc &&
                    <div className="cc-container email-info-row">
                        <div className="email-info-label">Cc:</div>
                        <div className="content">
                            <FormatAddresses addresses={message.cc} />
                        </div>
                    </div>}

                    {message.bcc &&
                    <div className="bcc-container email-info-row">
                        <div className="email-info-label">Bcc:</div>
                        <div className="content">
                            <FormatAddresses addresses={message.bcc} />
                        </div>
                    </div>}

                    {message.date &&
                    <div className="date-container email-info-row">
                        <div className="content">
                            {new Intl.DateTimeFormat('default', dateOptions).format(new Date(message.date))}
                        </div>
                    </div>}
                </div>
                
                {message.html &&
                <div className="html-container">
                    <div className="container-label">HTML:</div>
                    <div className="html-content">
                        <IframeResizer
                            iFrameRef={frameRef}
                            forwardRef={frameResizerRef}
                            checkOrigin={false}
                        />
                    </div>
                </div>}

                {!message.html && message.text &&
                <div className="text-container">
                    <div className="container-label">Text:</div>
                    <div className="text-content">
                        {message.text}
                    </div>
                </div>}

                {message.attachments && message.attachments.length > 0 &&
                <div className="attachments-container">
                    <div className="container-label">Attachments:</div>
                    <div className="content">
                        <Space size={[0, 'small']} wrap>
                            {message.attachments.map((attachment, index) =>
                                <Tag>
                                    <a
                                        key={index}
                                        title={attachment.filename || 'Вложение'}
                                        href={URL.createObjectURL(new Blob([attachment.content], {type: attachment.mimeType}))}
                                        download={attachment.filename || 'attachment'}
                                    >
                                        {attachment.filename || `attachment (${attachment.mimeType})`}
                                    </a>
                                </Tag>
                            )}
                        </Space>
                    </div>
                </div>}

            </div>}
        </>
    );
}