import { MutableRefObject } from "react";

export function assignRef<T>(refValue: T, ...refs: (React.Ref<T> | undefined)[]) {

    for(let i = 0; i < refs.length; i++) {

        const tmpRef = refs[i];

        if(typeof tmpRef === 'function') { tmpRef(refValue); }
        if(typeof tmpRef === 'object')   { (tmpRef as unknown as MutableRefObject<T>).current = refValue; }
    }
}

export function refSetter<T>(...refs: (React.Ref<T> | undefined)[]) {
    return (ref: T) => assignRef(ref, ...refs);
}