import iframeResizerLib from 'iframe-resizer'
import PropTypes from 'prop-types'
import React, { useEffect, useImperativeHandle, useRef } from 'react'
import filterIframeAttribs from './filter-iframe-attribs'
import { refSetter } from '../helpers';

const iframeResizer = iframeResizerLib.iframeResizer;

export type IFrameObject = {
    close: () => void;
    moveToAnchor: (anchor: string) => void;
    resize: () => void;
    sendMessage: (message: any, targetOrigin?: string) => void;
    removeListeners: () => void;
};

export interface IFrameComponent extends HTMLIFrameElement {
    iFrameResizer: IFrameObject;
}

export type IframeProps = React.DetailedHTMLProps<
    React.IframeHTMLAttributes<HTMLIFrameElement>,
    HTMLIFrameElement
>;

export type ResizerOptions = {
    log?: boolean;
    autoResize?: boolean;
    bodyBackground?: string;
    bodyMargin?: string | number;
    bodyPadding?: string | number;
    checkOrigin?: boolean | string[];
    iFrameRef?: React.Ref<HTMLIFrameElement>;
    forwardRef?: React.Ref<IFrameObject>;
    inPageLinks?: boolean;
    enablePublicMethods?: boolean;
    heightCalculationMethod?:
        | "bodyOffset"
        | "bodyScroll"
        | "documentElementOffset"
        | "documentElementScroll"
        | "max"
        | "min"
        | "grow"
        | "lowestElement"
        | "taggedElement";
    maxHeight?: number;
    maxWidth?: number;
    minHeight?: number;
    minWidth?: number;
    resizeFrom?: "parent" | "child";
    scrolling?: boolean | "auto";
    sizeHeight?: boolean;
    sizeWidth?: boolean;
    tolerance?: number;
    widthCalculationMethod?:
        | "bodyOffset"
        | "bodyScroll"
        | "documentElementOffset"
        | "documentElementScroll"
        | "max"
        | "min"
        | "scroll"
        | "rightMostElement"
        | "taggedElement";
};

export type ResizerEvents = {
    onInit?: (iframe: IFrameComponent) => void;
    onMessage?: (ev: { iframe: IFrameComponent; message: any }) => void;
    onResized?: (ev: {
        iframe: IFrameComponent;
        height: number;
        width: number;
        type: string;
    }) => void;
    onScroll?: (ev: { x: number; y: number }) => boolean;
};

export type IframeResizerProps = Omit<IframeProps, "scrolling"> &
    ResizerOptions &
    ResizerEvents;

const IframeResizer = (props: IframeResizerProps) => {
  
    const { title, forwardRef, iFrameRef, ...rest } = props;
    const iframeProps = filterIframeAttribs(rest);
    const internalIframeRef = useRef<IFrameComponent>(null);

    const onClose = () => {
        console.warn(
            !internalIframeRef.current,
            `[iframeSizerReact][${
                internalIframeRef && internalIframeRef.current && internalIframeRef.current.id
            }] Close event ignored, to remove the iframe update your React component`
        )
        return !internalIframeRef.current
    }

    // This hook is only run once, as once iframeResizer is bound, it will
    // deal with changes to the element and does not need recalling
    useEffect(() => {

        const iframe = internalIframeRef.current

        iframeResizer({ ...rest, closedCallback: onClose }, iframe!);

        return () => iframe?.iFrameResizer && iframe.iFrameResizer.removeListeners();

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(forwardRef, () => ({
        close:        () => internalIframeRef.current?.iFrameResizer.close(),
        resize:       () => internalIframeRef.current?.iFrameResizer.resize(),
        moveToAnchor: (anchor: string) => internalIframeRef.current?.iFrameResizer.moveToAnchor(anchor),
        sendMessage:  (message: any, targetOrigin?: string) => internalIframeRef.current?.iFrameResizer.sendMessage(message, targetOrigin),
        removeListeners: () => internalIframeRef.current?.iFrameResizer.removeListeners(),
    }));

    return (
        <iframe
            title={title}
            {...iframeProps}
            ref={refSetter(internalIframeRef, iFrameRef)}
        />
    );
}

IframeResizer.defaultProps = {
    title: 'iframe',
}

IframeResizer.propTypes = {
    title: PropTypes.string,
}

export default IframeResizer