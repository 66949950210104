import { IframeResizerProps } from "."

export default (props: IframeResizerProps) => {
    const {
      autoResize,
      bodyBackground,
      bodyMargin,
      bodyPadding,
      checkOrigin,
      inPageLinks,
      heightCalculationMethod,
      log,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      resizeFrom,
      scrolling,
      sizeHeight,
      sizeWidth,
      tolerance,
      widthCalculationMethod,
      onInit,
      onMessage,
      onResized,
      ...iframeProps
    } = props
  
    return iframeProps
}